import Hidden from "@material-ui/core/Hidden"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import AniLink from "gatsby-plugin-transition-link/AniLink"
import React, { useState } from "react"
import LinkStyle from "../styles/LinkStyle"
import Burger from "./burger"
import MenuItems from "./menuItems"
import MenuList from "./menuList"

const Menu = () => {
  const [open, setOpen] = useState(false)

  const data = useStaticQuery(graphql`
    query Menu {
      prismicBasicInfo {
        data {
          email {
            text
          }
          mobiel_raw {
            text
          }
          mobiel_pretty {
            text
          }
          orange
          main_color_three
          logo {
            localFile {
              childImageSharp {
                fixed(width: 150) {
                  ...GatsbyImageSharpFixed_withWebp
                }
              }
            }
          }
        }
      }
      prismicMenu {
        data {
          menu_item {
            display {
              text
            }
            link_to {
              text
            }
          }
        }
      }
    }
  `)

  const {
    email,
    mobiel_raw,
    mobiel_pretty,
    orange,
    main_color_three,
    logo,
  } = data.prismicBasicInfo.data
  const { menu_item } = data.prismicMenu.data

  const menuStyling = {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  }

  const subMenuStyling = {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  }

  const contactBoxStyling = {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    width: 145,
    height: 98,
    margin: 12.5,
    marginLeft: 0,
  }

  const genContactBoxStyling = {
    background: orange,
    height: "31%",
    color: main_color_three,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  }

  const loginContactBoxStyling = {
    borderRadius: "45px 5px 0 0",
  }

  const mailContactBoxStyling = {
    borderRadius: "0 0 5px 5px",
  }

  const contactLinkStyle = {
    ...LinkStyle,
    fontSize: 13,
  }

  const activeStyle = {
    color: orange,
  }

  return (
    <div style={{ menuStyling }}>
      <div style={subMenuStyling}>
        <AniLink paintDrip hex={orange} to="/" style={{ zIndex: 999999 }}>
          <Img
            fixed={logo.localFile.childImageSharp.fixed}
            title="logo"
            alt="logo"
          />
        </AniLink>

        <Hidden implementation="css" mdDown>
          <MenuItems
            open={true}
            setOpen={() => null}
            menuItems={menu_item}
            activeStyle={activeStyle}
          />
        </Hidden>

        <Hidden implementation="css" lgUp>
          <Burger open={open} setOpen={setOpen} />
        </Hidden>

        <div className="resp-hide" style={contactBoxStyling}>
          <div
            className="scale-link"
            style={{ ...loginContactBoxStyling, ...genContactBoxStyling }}
          >
            <a
              style={contactLinkStyle}
              href="http://mijn.mddd.nl"
              target="_blank"
              rel="noopener"
            >
              Login
            </a>
          </div>

          <div className="scale-link" style={{ ...genContactBoxStyling }}>
            <a style={contactLinkStyle} href={`tel:${mobiel_raw.text}`}>
              {mobiel_pretty.text}
            </a>
          </div>

          <div
            className="scale-link"
            style={{ ...mailContactBoxStyling, ...genContactBoxStyling }}
          >
            <a style={contactLinkStyle} href={`mailto:${email.text}`}>
              {email.text}
            </a>
          </div>
        </div>
      </div>

      <MenuList open={open} setOpen={setOpen} menuItems={menu_item} />
    </div>
  )
}

export default Menu
