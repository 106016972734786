import { graphql, useStaticQuery } from "gatsby"
import { bool, func } from "prop-types"
import React from "react"

const Burger = ({ open, setOpen }) => {
  const data = useStaticQuery(graphql`
    query burger {
      prismicBasicInfo {
        data {
          orange
        }
      }
    }
  `)

  const { orange } = data.prismicBasicInfo.data

  const width = 48

  const buttonStyling = {
    marginTop: -5,
    display: "flex",
    flexDirection: "column",
    background: "none",
    border: "none",
    cursor: "pointer",
    transition: "all 0.3s linear",
    transform: open ? "translateX(10px)" : "translateX(0)",
    zIndex: 9999999,
    marginLeft: 7,
  }

  const barStyling = {
    display: "inline-block",
    margin: 4,
    width: width,
    height: 6,
    background: orange,
    borderRadius: "1px 6px 1px 1px",
    transition: "all 0.3s linear",
    transformOrigin: 3,
  }

  const childOne = {
    transform: open ? "rotate(45deg)" : "rotate(0)",
  }

  const childTwo = {
    width: open ? 0 : width,
  }

  const childThree = {
    transform: open ? "rotate(-45deg)" : "rotate(0)",
  }

  return (
    <button
      aria-label="menu"
      style={buttonStyling}
      onClick={() => setOpen(!open)}
    >
      <div style={{ ...barStyling, ...childOne }} />
      <div style={{ ...barStyling, ...childTwo }} />
      <div style={{ ...barStyling, ...childThree }} />
    </button>
  )
}

Burger.propTypes = {
  open: bool.isRequired,
  setOpen: func.isRequired,
}

export default Burger
