import React from "react"
import { useStaticQuery, graphql } from "gatsby"

const LinkIcon = ({ options }) => {
  const data = useStaticQuery(graphql`
    query linkedin {
      prismicBasicInfo {
        data {
          main_color_three
        }
      }
    }
  `)

  const { main_color_three } = data.prismicBasicInfo.data

  return (
    <a
      className="scale-link"
      href={options.url}
      target="_blank"
      rel="external noopener noreferrer"
      style={{ width: options.width, height: options.height }}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={options.width}
        height={options.height}
        viewBox={options.viewBox}
        aria-label={options.ariaLabel}
      >
        <path fill={main_color_three} d={options.path} />
      </svg>
    </a>
  )
}

export default LinkIcon
