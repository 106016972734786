import React from "react";
import Menu from './Menu/menu';
import { useStaticQuery, graphql } from 'gatsby';

const Header = ({ font }) => {

  const data = useStaticQuery(graphql`
    query header {
      prismicBasicInfo {
          data {
              main_color_two
          }
      }
    }
  `);

  const { main_color_two } = data.prismicBasicInfo.data;

  const headerStyling = {
    background: main_color_two,
    zIndex: '99',
    position: 'sticky',
    top: 0,
    left: 0,
    width: '100%'
  }

  return (
    <header style={{ ...font, ...headerStyling }} >
      <Menu />
    </header>
  )

}

export default Header;
