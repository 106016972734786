import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"

const TransitionLink = ({
  children,
  to,
  linkStyle,
  activeStyle,
  extraClasses,
  clickHandler,
}) => (
  <Link
    to={to}
    className={"scale-link " + extraClasses}
    style={linkStyle}
    activeStyle={activeStyle}
    partiallyActive={to === "/" ? false : true}
    onClick={clickHandler}
  >
    {children}
  </Link>
)

TransitionLink.propTypes = {
  children: PropTypes.node.isRequired,
  to: PropTypes.string.isRequired,
  linkStyle: PropTypes.object,
  activeStyle: PropTypes.object,
  extraClasses: PropTypes.string,
  clickHandler: PropTypes.func,
}

TransitionLink.defaultProps = {
  linkStyle: {},
  extraClasses: "",
  clickHandler: () => {},
}

export default TransitionLink
