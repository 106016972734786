import { graphql, useStaticQuery } from "gatsby"
import { array, bool, object } from "prop-types"
import React from "react"
import ContactMenu from "../ContactMenu/contactMenu"
import Link from "../HelperComponents/TransitionLink"
import LinkStyle from "../styles/LinkStyle"

const MenuItems = ({ open, clickHandler, menuItems, activeStyle }) => {
  const data = useStaticQuery(graphql`
    query MenuItems {
      prismicBasicInfo {
        data {
          main_color_three
        }
      }
    }
  `)

  const { main_color_three } = data.prismicBasicInfo.data

  const ulStyle = {
    display: open ? "flex" : "none",
    justifyContent: "space-evenly",
    alignItems: "center",
    width: "100%",
  }

  const liStyle = {
    margin: 25,
    display: open ? "list-item" : "none",
  }

  return (
    <ul style={ulStyle} className="row-to-column">
      <li key={0} style={liStyle}>
        <Link
          to="/"
          linkStyle={{ ...LinkStyle, fontSize: 25 }}
          clickHandler={clickHandler}
          activeStyle={activeStyle}
        >
          home
        </Link>
      </li>

      {menuItems.map((item, i) => (
        <li key={i + 1} style={liStyle}>
          <Link
            to={`${item.link_to.text}/`}
            linkStyle={{ ...LinkStyle, fontSize: 25 }}
            clickHandler={clickHandler}
            activeStyle={activeStyle}
          >
            {item.display.text}
          </Link>
        </li>
      ))}

      <li key="10000" style={liStyle} className="resp-show">
        <ContactMenu
          hideLinkedIn={true}
          style={{
            fontSize: "150%",
            paddingBottom: 25,
            color: main_color_three,
          }}
        />
      </li>
    </ul>
  )
}

MenuItems.propTypes = {
  open: bool.isRequired,
  menuItems: array.isRequired,
  activeStyle: object,
}

export default MenuItems
