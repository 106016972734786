import { graphql, Link, useStaticQuery } from "gatsby"
import React from "react"
import ContactMenu from "./ContactMenu/contactMenu"
import SmallMenu from "./SmallMenu/smallMenu"
import LinkStyle from "./styles/LinkStyle"

const Footer = ({ font }) => {
  const data = useStaticQuery(graphql`
    query footer {
      prismicBasicInfo {
        data {
          kvk {
            text
          }
          orange
          site_title {
            text
          }
          main_color_two
          main_color_three
        }
      }
      prismicMenu {
        data {
          menu_item {
            display {
              text
            }
            link_to {
              text
            }
          }
        }
      }
    }
  `)

  const {
    kvk,
    site_title,
    orange,
    main_color_two,
    main_color_three,
  } = data.prismicBasicInfo.data

  const { menu_item } = data.prismicMenu.data

  const year = new Date().getFullYear()

  const footerStyling = {
    backgroundColor: main_color_two,
    color: main_color_three,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
  }

  const footerFlexStyling = {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
    width: "100%",
  }

  const footerColumnStyling = {
    // flex: "18%",
    padding: 50,
    marginTop: 25,
  }

  const lastFooterStyling = {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
    // marginRight: 25,
    size: 12,
  }

  return (
    <footer style={{ ...footerStyling, ...font }}>
      <div style={footerFlexStyling}>
        <div style={footerColumnStyling} className="footer-margin resp-footer">
          <SmallMenu menuItems={menu_item} />
        </div>

        <div style={footerColumnStyling} className="resp-footer">
          <ContactMenu />
        </div>

        <div
          style={footerColumnStyling}
          className="resp-hide-footer resp-footer"
        ></div>
        <div
          style={footerColumnStyling}
          className="resp-hide-footer resp-footer"
        ></div>

        <div
          style={{ ...footerColumnStyling, ...lastFooterStyling }}
          className="resp-footer last-footer"
        >
          <Link
            to="/circulair-geld-wordpress-woocommerce-plugin/"
            style={{
              color: main_color_three,
              textDecoration: "none",
              fontWeight: "bolder",
            }}
            className="scale-link"
          >
            Download hier de gratis
            <br /> WooCommerce plugin voor
            <br /> Circulair Geld!
          </Link>
        </div>
      </div>

      <span style={{ margin: "50px 25px 0" }} className="resp-center-text">
        {`© ${year}, ${site_title.text}  -  KvK: ${kvk.text}  -  `}
        <a
          style={{ color: main_color_three }}
          href="/algemene_voorwaarden.pdf"
          target="_blank"
        >
          algemene voorwaarden
        </a>
        {`  -  `}
        <a
          style={{ color: main_color_three }}
          href="/privacy_statement.pdf"
          target="_blank"
        >
          privacy statement
        </a>
      </span>

      <span
        style={{ margin: "25px 25px 15px", fontSize: "85%" }}
        className="resp-center-text"
      >
        powered by{" "}
        <a
          href="https://www.gatsbyjs.org"
          target="_blank"
          rel="noopener noreferrer"
          style={{ ...LinkStyle, color: orange }}
        >
          Gatsby
        </a>
      </span>
    </footer>
  )
}

export default Footer
