import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import Header from "./header"
import Footer from "./footer"
import "./layout.css"
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles"

import Font from "./styles/FontStyle"

const Layout = ({ children }) => {
  const {
    prismicBasicInfo: {
      data: { orange, main_color_two, main_color_three },
    },
  } = useStaticQuery(graphql`
    query layout {
      prismicBasicInfo {
        data {
          orange
          main_color_two
          main_color_three
        }
      }
    }
  `)

  const theme = createMuiTheme({
    palette: {
      primary: {
        main: orange,
      },
      secondary: {
        main: main_color_two,
      },
    },
  })

  const defaultTextStyle = {
    color: main_color_three,
  }

  return (
    <ThemeProvider theme={theme}>
      <Header font={Font} />

      <main style={{ ...defaultTextStyle, ...Font }}>{children}</main>

      <Footer font={Font} />
    </ThemeProvider>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
