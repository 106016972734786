import { graphql, useStaticQuery } from "gatsby"
import { array, bool } from "prop-types"
import React from "react"
import MenuItems from "./menuItems"

const MenuList = ({ open, setOpen, menuItems }) => {
  const data = useStaticQuery(graphql`
    query MenuList {
      prismicBasicInfo {
        data {
          orange
        }
      }
    }
  `)

  const { orange } = data.prismicBasicInfo.data

  const arrowWidth = "12px"

  const menuListStyle = {
    position: "absolute",
    background: orange,
    transition: "all 0.3s linear",
    width: "100%",
    opacity: open ? 1 : 0,
    display: "flex",
    alignItems: "center",
  }

  const clickHandler = () => setOpen(!open)

  return (
    <nav
      style={menuListStyle}
      className={open ? "menu open-menu" : "menu closed-menu"}
    >
      <div
        style={{
          position: "absolute",
          top: open ? `-${arrowWidth}` : 0,
          marginLeft: `-${arrowWidth}`,
          width: 0,
          height: 0,
          borderLeft: `${arrowWidth} solid transparent`,
          borderRight: `${arrowWidth} solid transparent`,
          borderBottom: `${arrowWidth} solid ${orange}`,
          transition: "all .35s ease-in-out",
        }}
        className="triangle-position"
      />

      <MenuItems
        menuItems={menuItems}
        clickHandler={clickHandler}
        open={open}
      />
    </nav>
  )
}

MenuList.propTypes = {
  open: bool.isRequired,
  menuItems: array.isRequired,
}

export default MenuList
