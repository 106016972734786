import React from "react"
import { array } from "prop-types"
import { Link } from "gatsby"
import { makeStyles } from "@material-ui/core/styles"

import LinkStyle from "../styles/LinkStyle"

const useStyles = makeStyles(theme => ({
  list: {
    "& li": {
      paddingBottom: theme.spacing(1.5),
    },
    "& li:last-child": {
      paddingBottom: 0,
    },
  },
}))

const SmallMenu = ({ menuItems }) => {
  const classes = useStyles()

  return (
    <nav>
      <ul className={classes.list}>
        <li key={0}>
          <Link
            to="/"
            className="scale-link"
            style={{ ...LinkStyle, fontSize: 16 }}
          >
            home
          </Link>
        </li>
        {menuItems.map((item, i) => (
          <li key={i + 1}>
            <Link
              to={`${item.link_to.text}/`}
              className="scale-link"
              style={{ ...LinkStyle, fontSize: 16 }}
            >
              {item.display.text}
            </Link>
          </li>
        ))}
      </ul>
    </nav>
  )
}

SmallMenu.propTypes = {
  menuItems: array.isRequired,
}

export default SmallMenu
