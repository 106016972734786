// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-bedankt-js": () => import("./../../../src/pages/bedankt.js" /* webpackChunkName: "component---src-pages-bedankt-js" */),
  "component---src-pages-circulair-geld-wordpress-woocommerce-plugin-js": () => import("./../../../src/pages/circulair-geld-wordpress-woocommerce-plugin.js" /* webpackChunkName: "component---src-pages-circulair-geld-wordpress-woocommerce-plugin-js" */),
  "component---src-pages-contact-jsx": () => import("./../../../src/pages/contact.jsx" /* webpackChunkName: "component---src-pages-contact-jsx" */),
  "component---src-pages-diensten-js": () => import("./../../../src/pages/diensten.js" /* webpackChunkName: "component---src-pages-diensten-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-over-mij-js": () => import("./../../../src/pages/over-mij.js" /* webpackChunkName: "component---src-pages-over-mij-js" */),
  "component---src-pages-portfolio-js": () => import("./../../../src/pages/portfolio.js" /* webpackChunkName: "component---src-pages-portfolio-js" */),
  "component---src-pages-utrechtse-euro-wordpress-woocommerce-plugin-js": () => import("./../../../src/pages/utrechtse-euro-wordpress-woocommerce-plugin.js" /* webpackChunkName: "component---src-pages-utrechtse-euro-wordpress-woocommerce-plugin-js" */),
  "component---src-pages-werkwijze-js": () => import("./../../../src/pages/werkwijze.js" /* webpackChunkName: "component---src-pages-werkwijze-js" */),
  "component---src-templates-basic-template-js": () => import("./../../../src/templates/basicTemplate.js" /* webpackChunkName: "component---src-templates-basic-template-js" */),
  "component---src-templates-dienst-template-js": () => import("./../../../src/templates/dienstTemplate.js" /* webpackChunkName: "component---src-templates-dienst-template-js" */),
  "component---src-templates-portfolio-template-js": () => import("./../../../src/templates/portfolioTemplate.js" /* webpackChunkName: "component---src-templates-portfolio-template-js" */)
}

