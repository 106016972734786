import { graphql, useStaticQuery } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import LinkIcon from "../../icons/LinkIcon"
import LinkStyle from "../styles/LinkStyle"

const ContactMenu = ({ className, style, hideLinkedIn }) => {
  const data = useStaticQuery(graphql`
    query contactMenu {
      prismicBasicInfo {
        data {
          email {
            text
          }
          mobiel_raw {
            text
          }
          mobiel_pretty {
            text
          }
          linkedin_url {
            url
          }
        }
      }
    }
  `)

  const {
    email,
    mobiel_raw,
    mobiel_pretty,
    linkedin_url,
  } = data.prismicBasicInfo.data

  const dimensions = 32

  const listStyle = {
    paddingBottom: 16,
    ...style,
  }

  const optionsLinkedIn = {
    width: dimensions,
    height: dimensions,
    url: linkedin_url.url,
    ariaLabel: "Link to LinkedIn account",
    viewBox: "0 0 448 512",
    path:
      "M416 32H31.9C14.3 32 0 46.5 0 64.3v383.4C0 465.5 14.3 480 31.9 480H416c17.6 0 32-14.5 32-32.3V64.3c0-17.8-14.4-32.3-32-32.3zM135.4 416H69V202.2h66.5V416zm-33.2-243c-21.3 0-38.5-17.3-38.5-38.5S80.9 96 102.2 96c21.2 0 38.5 17.3 38.5 38.5 0 21.3-17.2 38.5-38.5 38.5zm282.1 243h-66.4V312c0-24.8-.5-56.7-34.5-56.7-34.6 0-39.9 27-39.9 54.9V416h-66.4V202.2h63.7v29.2h.9c8.9-16.8 30.6-34.5 62.9-34.5 67.2 0 79.7 44.3 79.7 101.9V416z",
  }

  const optionsGithub = {
    width: dimensions,
    height: dimensions,
    url: "https://github.com/Marco-Daniel",
    ariaLabel: "Link to Github account",
    viewBox: "0 0 496 512",
    path:
      "M165.9 397.4c0 2-2.3 3.6-5.2 3.6-3.3.3-5.6-1.3-5.6-3.6 0-2 2.3-3.6 5.2-3.6 3-.3 5.6 1.3 5.6 3.6zm-31.1-4.5c-.7 2 1.3 4.3 4.3 4.9 2.6 1 5.6 0 6.2-2s-1.3-4.3-4.3-5.2c-2.6-.7-5.5.3-6.2 2.3zm44.2-1.7c-2.9.7-4.9 2.6-4.6 4.9.3 2 2.9 3.3 5.9 2.6 2.9-.7 4.9-2.6 4.6-4.6-.3-1.9-3-3.2-5.9-2.9zM244.8 8C106.1 8 0 113.3 0 252c0 110.9 69.8 205.8 169.5 239.2 12.8 2.3 17.3-5.6 17.3-12.1 0-6.2-.3-40.4-.3-61.4 0 0-70 15-84.7-29.8 0 0-11.4-29.1-27.8-36.6 0 0-22.9-15.7 1.6-15.4 0 0 24.9 2 38.6 25.8 21.9 38.6 58.6 27.5 72.9 20.9 2.3-16 8.8-27.1 16-33.7-55.9-6.2-112.3-14.3-112.3-110.5 0-27.5 7.6-41.3 23.6-58.9-2.6-6.5-11.1-33.3 2.6-67.9 20.9-6.5 69 27 69 27 20-5.6 41.5-8.5 62.8-8.5s42.8 2.9 62.8 8.5c0 0 48.1-33.6 69-27 13.7 34.7 5.2 61.4 2.6 67.9 16 17.7 25.8 31.5 25.8 58.9 0 96.5-58.9 104.2-114.8 110.5 9.2 7.9 17 22.9 17 46.4 0 33.7-.3 75.4-.3 83.6 0 6.5 4.6 14.4 17.3 12.1C428.2 457.8 496 362.9 496 252 496 113.3 383.5 8 244.8 8zM97.2 352.9c-1.3 1-1 3.3.7 5.2 1.6 1.6 3.9 2.3 5.2 1 1.3-1 1-3.3-.7-5.2-1.6-1.6-3.9-2.3-5.2-1zm-10.8-8.1c-.7 1.3.3 2.9 2.3 3.9 1.6 1 3.6.7 4.3-.7.7-1.3-.3-2.9-2.3-3.9-2-.6-3.6-.3-4.3.7zm32.4 35.6c-1.6 1.3-1 4.3 1.3 6.2 2.3 2.3 5.2 2.6 6.5 1 1.3-1.3.7-4.3-1.3-6.2-2.2-2.3-5.2-2.6-6.5-1zm-11.4-14.7c-1.6 1-1.6 3.6 0 5.9 1.6 2.3 4.3 3.3 5.6 2.3 1.6-1.3 1.6-3.9 0-6.2-1.4-2.3-4-3.3-5.6-2z",
  }

  return (
    <ul className={className}>
      <li key="1" style={listStyle}>
        e-mail:{" "}
        <a
          style={LinkStyle}
          className="scale-link"
          href={`mailto:${email.text}`}
        >
          {email.text}
        </a>
      </li>
      <li key="2" style={listStyle}>
        mobiel:{" "}
        <a
          style={LinkStyle}
          className="scale-link"
          href={`tel:${mobiel_raw.text}`}
        >
          {mobiel_pretty.text}
        </a>
      </li>
      <li key="mijn.mddd.nl" style={{ ...listStyle, textAlign: "center" }}>
        <a
          style={LinkStyle}
          className="scale-link"
          href="https://mijn.mddd.nl"
          target="_blank"
          rel="noopener"
        >
          mijn.mddd.nl
        </a>
      </li>

      {hideLinkedIn ? null : (
        <li>
          <ul
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "flex-end",
              paddingTop: 15,
            }}
          >
            <li key="3" style={{ ...listStyle, padding: "0 15px" }}>
              <LinkIcon options={optionsLinkedIn} />
            </li>
            <li key="4" style={{ ...listStyle, padding: "0 15px" }}>
              <LinkIcon options={optionsGithub} />
            </li>
          </ul>
        </li>
      )}
    </ul>
  )
}

ContactMenu.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  hideLinkedIn: PropTypes.bool,
}

ContactMenu.defaultProps = {
  hideLinkedIn: false,
}

export default ContactMenu
